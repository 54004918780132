<template>
  <div class="card filterbox">
    <div class="card-header p-0">
      <button class="btn btn-light btn-block filterbox__header p-3" :class="{ 'not-collapsed': !isCollapsed }" @click="toogleCollapse">
        <span>{{ title }}</span>
        <span class="filterbox__icon"><b-icon icon="caret-down-fill" h-flip /></span>
      </button>
    </div>
    <div class="collapse" :class="{ show: !isCollapsed }">
      <div class="card-body pt-0 px-3 pb-2">
        <div class="card__search-wrap">
          <b-icon icon="search" class="card__search-icon"></b-icon>
          <input v-model="searchInput" class="form-control mb-3" name="search" type="text" :placeholder="$t('filter.brands.placeholder')" />
        </div>
        <div role="group" class="brand-overview bv-no-focus-ring">
          <div v-for="brand in filteredBrands" :key="brand" class="mb-2">
            <div class="custom-control custom-control-inline custom-checkbox">
              <input :id="brand" v-model="selectedBrands" :value="brand" type="checkbox" class="custom-control-input" @change="handleInput" />
              <label :for="brand" class="custom-control-label">
                <span>{{ brand }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';

  export default {
    components: {
      BIcon
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      brands: {
        type: Array,
        default: null
      },
      activeBrands: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        selectedBrands: this.activeBrands,
        searchInput: '',
        isCollapsed: false
      };
    },
    computed: {
      filteredBrands() {
        return this.brands.filter((brand) => brand.toLowerCase().includes(this.searchInput.toLowerCase()));
      }
    },
    watch: {
      activeBrands() {
        this.selectedBrands = this.activeBrands;
      }
    },
    methods: {
      handleInput() {
        this.$emit('filterBrands', this.selectedBrands);
      },
      toogleCollapse() {
        this.isCollapsed = !this.isCollapsed;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filterbox {
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius;
    margin: 0 auto 2rem auto;
    max-width: 380px;

    .card-body {
      @extend %font-md;
    }
  }

  .card__search-wrap {
    position: relative;

    .card__search-icon {
      position: absolute;
      left: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
      color: $gray-300;
    }

    input {
      background-color: $gray-100;
      border: none;
      padding: 1rem 1.5rem 1rem 3.5rem;
    }
  }

  .brand-overview {
    max-height: 10.5rem;
    overflow-y: auto;
    padding-top: 4px;
    margin-top: -4px;
    padding-left: 4px;
    margin-left: -4px; /* used to avoid cutoff focus ring on checkbox*/
    margin-bottom: 0.5rem;
  }
</style>
