<template>
  <section class="login">
    <StageLoginShape :image="image" />
    <StageLoginCard
      :headline="headline"
      :button-text="buttonText"
      :inputPlaceholder="inputPlaceholder"
      :fontColor="fontColor"
    />
  </section>
</template>

<script>
import StageLoginShape from './StageLoginShape.vue';
import StageLoginCard from './StageLoginCard.vue';
export default {
  components: {
    StageLoginShape,
    StageLoginCard
  },
  props: {
    headline: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    inputPlaceholder: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: {}
    },
    fontColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    position: relative;
    overflow: hidden;
    @extend %spacer-bottom;
    @include media-breakpoint-up(md) {
      height: 480px;
    }
    @include media-breakpoint-up(lg) {
      height: 640px;
    }
  }
</style>
