<template>
  <div class="legal-text">
    <div class="m-0">
      <div class="custom-control custom-checkbox cb-container">
        <input v-model="isChecked" type="checkbox" class="custom-control-input" id="termsAndConditions"  @change="toggleCheckbox"/>
        <label class="custom-control-label" for="termsAndConditions"></label>
      </div>
      <p class="legal-text-p">{{ $t('view.checkout.cart.summary.legal_agb1') }}</p>
      <a v-if="AGBLink" :href="AGBLink" target="_blank" rel="noopener">{{ $t('view.checkout.cart.summary.legal_agb_link') }}</a>
      <p class="legal-text-p">{{ $t('view.checkout.cart.summary.legal_agb2') }}</p>
    </div>
    <p>{{ $t('view.checkout.cart.summary.legal_voucher') }}</p>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isChecked: false
      };
    },
    computed: {
      AGBLink() {
        return this.$routeHandler('/agb');
      }
    },
    methods:{
      toggleCheckbox() {
        this.$emit('toggle-checkbox', this.isChecked);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .legal-text {
    @extend %font-sm;

    &-p {
      margin-bottom: 0;
      display: inline;
    }
  }
  .cb-container {
    display: inline;
    width: 1.25rem;
    height: 1.25rem;
  }
  .custom-control {
    padding-left: 0;
    margin-right: 1.5rem;
  }
  .custom-control-label{
    padding: 0;
    
    &::before,
    &::after {
      left: 0;
    }
  }
  .custom-control-label::before,
  .custom-control-label::after {
    width: 1.25rem;
    height: 1.25rem;
  }
</style>
