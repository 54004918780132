<template>
  <div>
    <div class="filter__body">
      <div data-test="filter-sort">
        <FilterBoxRadio
          :title="filterGroupsSorting.title"
          :options="filterGroupsSorting.options"
          :active-filter="sortOption"
          @filterRadio="updateSorting"
        />
      </div>
      <div data-test="filter-product">
        <FilterBox
          :title="filterGroupsProductType.title"
          :options="filterGroupsProductType.options"
          :active-filter="productTypeFilter"
          @filterInput="updateProductTypeFilter"
        />
      </div>
      <div data-test="filter-range">
        <FilterBoxRangeRotated
          :title="filterGroupsPrice.title"
          :min-range="priceRangeMin"
          :max-range="priceRangeMax"
          :price-min-filter="priceMinFilter"
          :price-max-filter="priceMaxFilter"
          :discount-filter="discountFilter"
          @filterRangeInput="updatePriceFilter"
          @discountClick="updatetDiscountFilter"
        />
      </div>
      <div v-if="showBrandFilter" data-test="filter-brand">
        <FilterBrandsSecond
          :title="$t('filter.brands.headline')"
          :brands="brands"
          :active-brands="selectedBrands"
          @filterBrands="updateBrandFilter"
        />
      </div>
      <div data-test="filter-shipping">
        <FilterBox
          :title="filterGroupsShippingMethod.title"
          :options="filterGroupsShippingMethod.options"
          :active-filter="shippingMethodFilter"
          @filterInput="updateShippingMethodFilter"
        />
      </div>
      <div data-test="filter-redemption">
        <FilterBox
          :title="filterGroupsRedemptionType.title"
          :options="filterGroupsRedemptionType.options"
          :active-filter="redemptionTypeFilter"
          @filterInput="updateRedemptionTypeFilter"
        />
      </div>
    </div>
    <div class="filter__footer">
      <div class="filter__footer-btns">
        <DefaultButton variant="light" @click="resetFilters()" class="filter__footer-btn mr-3" data-test="filter-reset-btn">
          {{ $t('filter.reset_short')}}
        </DefaultButton>
        <DefaultButton variant="primary" @click="$emit('button-click')" data-test="filter-show-btn">
          {{ $t('filter.show_results', { product_count: productCount }) }}
        </DefaultButton>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import FilterBoxRangeRotated from './FilterBoxRangeRotated';
  import FilterBrandsSecond from './FilterBrandsSecond';
  import FilterBox from '@/components/Filter/FilterBox.vue';
  import FilterBoxRadio from '@/components/Filter/FilterBoxRadio.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
   ;

  export default {
    data() {
      return {
        selectedSorting: this.sortOption
      };
    },
    components: {
      FilterBoxRangeRotated,
      FilterBrandsSecond,
      FilterBox,
      FilterBoxRadio,
      DefaultButton
    },
    computed: {
      ...mapGetters({
        productCount: 'filter/getFilteredProductsCount',
        priceRangeMin: 'filter/getMinPriceOfAllProducts',
        priceRangeMax: 'filter/getMaxPriceOfAllProducts',
        filterTags: 'filter/getFilterTags',
        isSearchActive: 'filter/isSearchActive',
        brands: 'filter/getBrands',
        categories: 'filter/getCategories',
        composedQuery: 'filter/composedQuery'
      }),
      ...mapState({
        productTypeFilter: (state) => state.filter.productType,
        redemptionTypeFilter: (state) => state.filter.redemptionType,
        shippingMethodFilter: (state) => state.filter.shippingMethod,
        discountFilter: (state) => state.filter.discount,
        sortOption: (state) => state.filter.sortOption,
        showOnlyRecommendedProducts: (state) => state.filter.onlyRecommendations,
        searchInput: (state) => state.filter.searchInput,
        selectedBrands: (state) => state.filter.selectedBrands,
        category: (state) => state.filter.category,
        activeCategory: (state) => state.filter.activeCategory,
        priceMinFilter: (state) => state.filter.priceMin,
        priceMaxFilter: (state) => state.filter.priceMax
      }),
      showBrandFilter() {
        return this.productTypeFilter.some(el => {
          return el.value === 'PHY'
        });
      },
      filterGroupsSorting() {
        return {
          title: 'Sortieren',
          options: [
            { value: 'default', text: this.$t('filter.sort.item1') },
            { value: 'byDiscount', text: this.$t('filter.sort.item0') },
            { value: 'priceDescending', text: this.$t('filter.sort.item3') },
            { value: 'priceAscending', text: this.$t('filter.sort.item2') },
            { value: 'nameAscending', text: this.$t('filter.sort.item4') },
            { value: 'nameDescending', text: this.$t('filter.sort.item5') }
          ]
        };
      },
      filterGroupsProductType() {
        return {
          title: this.$t('filter.group.type.title'),
          options: [
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_vouchers'),
              value: 'TVO'
            },
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_brand_products'),
              value: 'PHY'
            },
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_theme_vouchers'),
              value: 'UVO'
            }
          ]
        };
      },
      filterGroupsPrice() {
        return {
          title: this.$t('filter.group.price.title', { currency: this.$store.state.app.currency.code })
        };
      },
      filterGroupsShippingMethod() {
        return {
          title: this.$t('filter.group.shipping.title'),
          options: [
            {
              groupId: 'shippingMethod',
              text: this.$t('filter.group.shipping.option_mail'),
              value: 1
            },
            {
              groupId: 'shippingMethod',
              text: this.$t('filter.group.shipping.option_post'),
              value: 0
            }
          ]
        };
      },
      filterGroupsRedemptionType() {
        return {
          title: this.$t('filter.group.property.title'),
          options: [
            {
              groupId: 'redemptionType',
              text: this.$t('filter.group.property.option_online'),
              value: 'online_redeemable'
            },
            {
              groupId: 'redemptionType',
              text: this.$t('filter.group.property.option_store'),
              value: 'offline_redeemable'
            },
            {
              groupId: 'redemptionType',
              text: this.$t('filter.group.property.option_print'),
              value: 'printable_as_barcode'
            },
            {
              groupId: 'redemptionType',
              text: this.$t('filter.group.property.option_mobile'),
              value: 'usable_on_smartphone'
            }
          ]
        };
      }
    },
    watch: {
      sortOption() {
        this.selectedSorting = this.sortOption;
      }
    },
    
    mounted() {
      this.selectedSorting = this.sortOption;
    },

    methods: {
      updateProductTypeFilter(filterArray) {
        this.$store.commit('filter/SET_PRODUCTTYPE', filterArray);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateShippingMethodFilter(filterArray) {
        this.$store.commit('filter/SET_SHIPPINGMETHOD', filterArray);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateRedemptionTypeFilter(filterArray) {
        this.$store.commit('filter/SET_REDEMPTIONTYPE', filterArray);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updatetDiscountFilter(discountValue) {
        this.$store.commit('filter/SET_DISCOUNT', discountValue);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updatePriceFilter(priceObject) {
        this.$store.dispatch('filter/updatePriceFilter', priceObject);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateBrandFilter(selectedBrands) {
        this.$store.commit('filter/SET_SELECTED_BRANDS', selectedBrands);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateCategoryFilter(category) {
        this.$store.commit('filter/SET_CATEGORY', null);
        this.$store.commit('filter/SET_CATEGORY', category);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateSorting(sortOption) {
        this.$store.commit('filter/SET_SORTOPTION', sortOption);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      resetFilters() {
        this.$store.commit('filter/SET_SEARCHINPUT', '');
        this.$store.commit('filter/SET_PAGE', 1);
        this.$emit('reset');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filter__tags {
    padding: 0.5rem 0;
    .form-control {
      border: none;
      box-shadow: none;
      border-radius: none;
      padding: 0;
      transition: none;
    }
    .button-reset {
      @extend %font-sm;
    }
  }
  .filter__body {
    padding: 1rem;
  }

  .filter__footer {
    background: white;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 120;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #ccc;

    &-btn {
      border: 1px solid var(--primary) !important;
      color: var(--dark);
    }

    &-btns {
      max-width: 380px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      border: 1px solid transparent;
      max-width: 100%;
      width: 100%;
      @extend %font-h5;
    }
  }
</style>
