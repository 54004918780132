<template>
  <div class="product-overview--filter-sidebar">
    <section>
      <div class="container-xl">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="page-title">{{ computedHeadline }}</h1>
            <div>
              <CategoriesBarSwiper />
            </div>
            <div>
              <FilterStatusBarAlwaysVisible @showFilterSidebar="showFilterSidebar" />
            </div>
            <div class="sidebar-filter__wrapper">
              <Sidebar id="filterSidebar" ref="filterSidebar">
                <template #body="{ hideSidebar }">
                  <FilterGroupSideBar @reset="resetAllFilters" @button-click="hideFilterSidebar(hideSidebar)" />
                </template>
              </Sidebar>
              <DefaultButton
                variant="primary"
                button-text="Filter"
                :nested-badge="filterCount > 0"
                :badge-text="filterCount.toString()"
                shadowed-button
                class="floating-button"
                @click="showFilterSidebar"
              />
            </div>
            <div v-if="filteredProducts.length > 0" class="filter-results">
              <ProductItem v-for="product in filteredProducts" :key="product.id" :product="product" />
            </div>
            <div v-else>
              <div class="row d-flex align-content-center">
                <div class="col filter-results__empty-message">
                  <DefaultHeadline :headline-text="$t('filter.no_results')" heading-size="h5" is-headline is-centered />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <DefaultHeadline :headline-text="$t('global.product_slider.headline')" heading-size="h4" is-headline />
                </div>
              </div>
              <div class="filter-results">
                <ProductItem v-for="product in recommendedProducts" :key="product.id" :product="product" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import FilterStatusBarAlwaysVisible from '@/components/FilterSideBar/FilterStatusBarAlwaysVisible';
  import FilterGroupSideBar from '@/components/FilterSideBar/FilterGroupSideBar.vue';
  import CategoriesBarSwiper from '@/components/Categories/CategoriesBarSwiper.vue';
  import Sidebar from 'building-blocks/components/Sidebar.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import DefaultHeadline from 'building-blocks/components/DefaultElements/DefaultHeadline.vue';
  import ProductItem from '@/components/Product/ProductItem.vue';

  export default {
    components: {
      FilterStatusBarAlwaysVisible,
      FilterGroupSideBar,
      CategoriesBarSwiper,
      Sidebar,
      DefaultButton,
      DefaultHeadline,
      ProductItem
    },
    props: {
      recommendedProducts: {
        type: Array,
        default: []
      },
      filteredProducts: {
        type: Array,
        default: []
      },
      filterCount: {
        type: Number,
        default: 0
      },
      computedHeadline: {
        type: String,
        default: ''
      }
    },
    methods: {
      showFilterSidebar() {
        this.$emit('showFilterSidebar');
      },
      resetAllFilters() {
        this.$emit('resetAllFilters');
      },
      hideFilterSidebar(hideSidebar) {
        this.$emit('hideFilterSidebar', hideSidebar);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .product-overview--filter-sidebar {
    .page-title {
      margin: 2rem 0;
      font-weight: bold;

      @include media-breakpoint-down(xs) {
        margin: 1rem 0 2rem;
      }
    }

    .filter-results > article {
      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 2rem;
      }
    }
  }
</style>
