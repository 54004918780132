<template>
  <div id="faq" class="accordion">
    <div class="container-xl">
      <h1 class="mb-3">{{ headline }}</h1>
      <div v-for="group in groups" :key="group.headline" class="accordion-group">
        <div class="row">
          <div class="col-md-4">
            <h3 class="mb-4">{{ group.headline }}</h3>
          </div>
          <div class="col-md-8">
            <div role="tablist">
              <AccordionCard
                v-for="card in group.cards"
                :key="card.header"
                :card-content="card"
                :is-open="card.header === openCard"
                variant="light"
                @toggle="toggleActiveCard"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AccordionCard from './AccordionCard.vue';
  export default {
    components: { AccordionCard },
    props: {
      headline: {
        type: String
      },
      groups: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        openCard: null
      };
    },
    methods: {
      toggleActiveCard(cardName) {
        this.openCard = cardName;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .accordion {
    @extend %spacer-bottom;
  }

  .accordion-group {
    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }

    .btn:not(:disabled):not(.disabled):active:focus,
    .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: none;
    }
  }
</style>
