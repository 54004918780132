<template>
  <div class="filter__wrap">
    <div class="row filter__row">
      <div class="col-lg-10 col-md-12">
        <div class="filtertags">
          <button class="btn filtertags__btn" :class="{ 'filtertags__btn-filled': sortingOption.value !== 'default' }" @click="openSideBar()">
            {{ sortingOption.text }}
          </button>
          <button
            v-for="tag in defaultTags"
            :key="tag.text"
            class="btn filtertags__btn"
            :class="{ 'filtertags__btn-filled': tag.selected, 'filtertags--hidden': tag.hidden }"
            @click="openSideBar()"
          >
            {{ tag.text }}
          </button>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="sorting">
          <div class="sorting__count">{{ $t('filter.amount', { product_count: productCount }) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  export default {
    data() {
      return {
        selectedSorting: this.sortOption,
        defaultTags: {
          productType: { text: this.$t('filter.filter_bar.product_type'), selected: false },
          price: { text: this.$t('filter.filter_bar.price'), selected: false },
          brand: { text: this.$t('filter.filter_bar.brand'), selected: false },
          shippingMethod: { text: this.$t('filter.filter_bar.delivery_method'), selected: false },
          redemptionType: { text: this.$t('filter.filter_bar.characteristics'), selected: false },
          category: { text: 'Category', selected: false, hidden: true },
          discount: { text: 'Discount', selected: false, hidden: true }
        }
      };
    },
    computed: {
      ...mapGetters({
        productCount: 'filter/getFilteredProductsCount',
        filterTags: 'filter/getFilterTags'
      }),
      ...mapState({
        productTypeFilter: (state) => state.filter.productType,
        redemptionTypeFilter: (state) => state.filter.redemptionType,
        shippingMethodFilter: (state) => state.filter.shippingMethod,
        sortOption: (state) => state.filter.sortOption,
        selectedBrands: (state) => state.filter.selectedBrands
      }),
      sortingOption() {
        const allOptions = [
          { value: 'default', text: this.$t('filter.sort.item1') },
          { value: 'byDiscount', text: this.$t('filter.sort.item0') },
          { value: 'priceDescending', text: this.$t('filter.sort.item3') },
          { value: 'priceAscending', text: this.$t('filter.sort.item2') },
          { value: 'nameAscending', text: this.$t('filter.sort.item4') },
          { value: 'nameDescending', text: this.$t('filter.sort.item5') }
        ];

        let selectedOption = { value: 'default', text: this.$t('filter.sort.item1') };

        allOptions.forEach((el) => {
          if (this.selectedSorting === el.value) {
            selectedOption = { value: el.value, text: el.text };
          }
        });

        return selectedOption;
      }
    },
    watch: {
      sortOption() {
        this.selectedSorting = this.sortOption;
      },
      filterTags() {
        this.showBrandFilter();
        this.clearFilterTags();
        this.fillUpFilterTags();
      }
    },
    mounted() {
      this.selectedSorting = this.sortOption;
      this.fillUpFilterTags();
    },
    methods: {
      openSideBar() {
        this.$emit('showFilterSidebar');
      },
      fillUpFilterTags() {
        this.filterTags.forEach((el) => {
          this.defaultTags[el.groupId].selected = true;
          if (el.groupId === 'discount') {
            this.defaultTags['price'].selected = true;
          }
        });
      },
      clearFilterTags() {
        for (const key in this.defaultTags) {
          this.defaultTags[key].selected = false;
        }
      },
      showBrandFilter() {
        if (this.productTypeFilter) {
          const isPHY = this.productTypeFilter.some(el => {
            return el.value === 'PHY'
          });

          this.defaultTags['brand'].hidden = !isPHY;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filter__wrap {
    padding-bottom: 3rem;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }
  }

  .filter__row {
    align-items: center;
  }

  .filtertags {
    @extend %font-sm;

    @include scrollbar(primary, md, 992px);

    &__reset {
      @extend %font-sm;
    }

    &__btn {
      cursor: pointer;
      padding: 1rem;
      border: 1px solid var(--primary);
      margin-right: 1rem;
      font-size: 0.875rem;
      color: var(--dark);
      transition: $dur $ease;

      &:hover {
        color: var(--primary);
        background-color: var(--primary-lightest);
      }

      @include media-breakpoint-down(xs) {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-right: 0;
      }

      &-filled {
        background-color: var(--primary);
        color: $light;
      }
    }
    &--hidden {
      display: none;
    }
  }
  .sorting {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
      border-bottom: 1px solid $gray-100;
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    &__select {
      text-align: right;
      .custom-select {
        font-size: $font-size-sm;
        height: auto;
        width: 160px;
      }
    }
  }
</style>
