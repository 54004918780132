import paymentTypes from '@/config/paymentTypes.js';

// =======================================================================
// State
// =======================================================================
const state = () => {
  return {
    netPrice: 0,
    grossPrice: 0,
    totalPrice: 0,
    tax: 0,
    discount: 0,
    discountPercentage: 0,
    prepaidValue: 0,
    missingAmountOfMoney: null,
    basketNumber: null,
    deliveryAddress: {},
    invoiceAddress: {},
    deliveryAddressWithoutEmailEditable: true,
    deliveryAddressEmailEditable: true,
    completeInvoiceAddressNeeded: false,
    positions: [],
    payments: [],
    lastProduct: {},
    paymentInProcess: '',
    shopCustomization: null,
    impersonalAccounts: null,
    debitorQuestionnaire: null,
    questionnaireAnswered: [],
  };
};

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  /**
   * Overwrites the whole basket.
   * @param {*} state
   * @param {*} basket
   */
  SET_BASKET(state, basket) {
    state.basketNumber = basket.basket_id;
    state.positions = basket.product_position;
    state.netPrice = basket.net_price;
    state.grossPrice = basket.gross_price;
    state.totalPrice = basket.total_price;
    state.tax = basket.tax;
    state.missingAmountOfMoney = basket.missing_amount_of_money;
    state.discount = basket.discount;
    state.discountPercentage = basket.discount_percentage;
    state.prepaidValue = basket.prepaid_value;
    state.deliveryAddress = basket.delivery_address;
    state.invoiceAddress = basket.invoice_address;
    state.deliveryAddressWithoutEmailEditable = basket.delivery_address_without_email_editable_by_user;
    state.deliveryAddressEmailEditable = basket.delivery_address_email_editable_by_user;
    state.completeInvoiceAddressNeeded = basket.is_complete_invoice_address_by_post_payment_needed;
    state.payments = basket.payments;
    state.shopCustomization = basket.shop_customization;
    state.impersonalAccounts = basket.impersonal_accounts;
    state.debitorQuestionnaire = basket.debitor_questionnaire;
    state.questionnaireAnswered = basket.answered_questionnaire;
  },
  /**
   * Erases the whole basket.
   * @param {*} state
   */
  RESET_BASKET(state) {
    state.basketNumber = null;
    state.positions = [];
    state.netPrice = 0;
    state.grossPrice = 0;
    state.totalPrice = 0;
    state.tax = 0;
    state.missingAmountOfMoney = null;
    state.discount = 0;
    state.discountPercentage = 0;
    state.prepaidValue = 0;
    state.deliveryAddress = {};
    state.invoiceAddress = {};
    state.deliveryAddressWithoutEmailEditable = true;
    state.deliveryAddressEmailEditable = true;
    state.completeInvoiceAddressNeeded = false;
    state.payments = [];
    state.lastProduct = {};
    state.paymentInProcess = '';
    state.shopCustomization = null;
    state.impersonalAccounts = null;
    state.debitorQuestionnaire = null;
    state.questionnaireAnswered = [];
  },

  SET_LAST_PRODUCT(state, product) {
    state.lastProduct = product;
  },

  SET_PAYMENT_IN_PROCESS(state, data) {
    state.paymentInProcess = data;
  }
};

// =======================================================================
// Actions
// =======================================================================
const actions = {
  /**
   * Includes additional update routinges, which should be triggered when we update the basket
   * @param {*} context
   * @param {*} basket
   */
  updateBasket(context, basket) {
    context.commit('SET_BASKET', basket);

    const generationProfileId = context.rootState.app.websiteSettings.generation_profile_id;
    if(generationProfileId && context.rootGetters['punchout/isPunchout']) {
      context.dispatch('product/addCatalog', { catalogId: generationProfileId, loaded: false } , { root: true });
    } else {
      const catalogIds = basket.catalog_ids || [];
      catalogIds.forEach((catalogId) => {
        context.dispatch('product/addCatalog', { catalogId, loaded: false } , { root: true });
      });
    }
  },
  /**
   * Basket creation. We need create the basket on C*Vision side and only retrieve the basekt number, for further updates.
   * @param {*} context
   * @throws ApiError
   */
  async createBasket(context) {
    const defaultCatalogId = context.rootState.app.websiteSettings.default_catalog_id;
    const isHandshake = context.rootState.app.isHandshake;
    const isOpenShop = context.rootState.app.isOpenShop;
    const catalogs = context.rootState.product.catalog;
    // TODO: Add isOpenShop condition
    if (context.state.basketNumber === null) {
      const responseData = await this.$api.createBasket();
      const basket = responseData.basket;
      // If catalog array is empty that's mean that this is first login/code and we want to prevent default catalog to go in that array and be loaded
      // Regular catalog will come in next response from verifyLoginCode
      if (!catalogs.length && !isHandshake && !isOpenShop) {
        basket.catalog_ids = basket.catalog_ids.filter(item => item !== defaultCatalogId);
      }
      context.dispatch('updateBasket', basket);
    }
  },
  /**
   * Loads a basket object if one exists.
   * @param {*} context
   * @throws ApiError
   */
  async loadBasket(context) {
    const responseData = await this.$api.loadBasket();
    const basket = responseData.basket;
    context.dispatch('updateBasket', basket);
  },
  /**
   * @param {*} context
   * @param {*} product
   */
  addProductToBasket(context, productData) {
    const dataHandler = {        
      product_id: productData.number,
      variation_id: productData.variation_id,
      product_quantity: productData.quantity,
      face_value: productData.face_value,
      delivery_option: productData.delivery_options};
    if(productData.selected_answer !== undefined) {
      dataHandler['questionnaire'] = {
        answer_a: productData.selected_answer === 0,
        answer_b: productData.selected_answer === 1,
        no_answer: productData.selected_answer !== null ? false : true,
      }
    }
    const updateData = {
      update_type: 'ADD',
      data: [dataHandler]
    };
    context.commit('SET_LAST_PRODUCT', updateData.data[0]);
    return context.dispatch('requestAPIBasketUpdate', updateData);
  },
  /**
   * @param {*} context
   * @param {*} positionNumber
   */
  removePositionFromBasket(context, positionNumber) {
    const updateData = {
      update_type: 'REMOVE',
      data: [{ position: positionNumber }]
    };
    context.dispatch('requestAPIBasketUpdate', updateData);
  },
  /**
   * Currently unused but available in the api. Should be left in for reference.
   * @param {*} context
   * @param {*} positionNumber
   */
  changeDeliveryType(context, positionNumber) {
    const updateData = {
      update_type: 'CHANGE_DELIVERY_TYPE',
      data: [{ position: positionNumber }]
    };
    context.dispatch('requestAPIBasketUpdate', updateData);
  },
  /**
   * @param {*} context
   * @param {*} positionNumber
   */
  changePositionQuantity(context, positionData) {
    const updateData = {
      update_type: 'CHANGE_QUANTITY',
      data: [
        {
          position: positionData.positionNumber,
          quantity: positionData.quantity
        }
      ]
    };
    return context.dispatch('requestAPIBasketUpdate', updateData);
  },
  /**
   * @param {*} context
   * @param {*} paymentTypeId
   */
  removePaymentType(context, payment) {
    const updateData = {
      update_type: 'REMOVE_PAYMENT',
      data: [{ payment_type_id: payment.payment_type_id, payment_id: payment.partial_payment_id }]
    };
    return context.dispatch('requestAPIBasketUpdate', updateData);
  },
  /**
   * Sends the update data to the backend and set the received updated basket in the store.
   * @param {*} context
   * @param {*} updateData
   * @throws ApiError
   */
  async requestAPIBasketUpdate(context, updateData) {
    const basket = await this.$api.updateBasket(updateData);
    context.dispatch('updateBasket', basket);
  },
  /**
   * Adds a banktransfer payment to the basket objects. Commits the updated basket to the store.
   * @param {*} context
   * @param {*} paymentAmount
   */
  async addBanktransferPaymentToBasket(context, paymentAmount) {
    const updateData = { amount: paymentAmount };
    const basket = await this.$api.addBankTransferPayment(updateData);
    context.dispatch('updateBasket', basket);
  },

  /**
   * Add a saferpay (credit card) payment to the basket objects.
   * @param {*} context
   * @param {*} params
   */
  async addSaferpayPaymentToBasket(context, params) {
    const basket = await this.$api.addSaferpayPayment(params);
    context.dispatch('updateBasket', basket);
  },

  /**
   * Verify saferpay payment.
   * @param {*} context
   * @param {*} params
   */
  async verifySaferpay(_context, params) {
    return await this.$api.verifySaferpay(params);
  },

  /**
   * Payment in process. Without param checking status from BFF.
   * @param {*} context
   * @param {*} param
   */
  async paymentInProcess(context, param) {
    const response = await this.$api.paymentInProcess(param);
    context.commit('SET_PAYMENT_IN_PROCESS', response);
    return response;
  },

  /**
   * Reset Serialized basket
   */
  async resetSerialized() {
    return await this.$api.resetSerialized();
  },

  /**
   * @param {*} context
   * @param {*} user_name
   * @param {*} password
   * @throws ApiError 
   */
  async verifyUserCredentials(context, user_info) {
    const updateData = { user_name: user_info.user_name, password: user_info.password };
    await this.$api.verifyUserLogin(updateData);
    const responseData = await this.$api.createBasket();
    const basket = responseData.basket;
    context.dispatch('updateBasket', basket);
  },


  /**
   * Adds a banktransfer payment to the basket objects. Commits the updated basket to the store.
   * @param {*} context
   * @param {*} code
   * @throws ApiError
   */
  async verifyLoginCode(context, code) {
    const updateData = { code: code };
    const responseData = await this.$api.verifyLogin(updateData);
    const basket = responseData.basket;
    context.dispatch('updateBasket', basket);
  },

  /**
   * Send a request to validate the addresses on the basket. Can receive a updated basket version.
   * @param {*} context
   * @param {*} data
   * @returns {*} validationResult
   */
  async validateBasketAddresses(context) {
    let apiResponse;
    try {
      apiResponse = await this.$api.validateBasketAddresses(context.state.basketNumber);
    } catch (error) {
      apiResponse = error.response;
    }

    const validationResult = {
      deliveryAddressValidationResult: null,
      invoiceAddressValidationResult: null
    };

    // always need to set the updated basket, even if we have errors, because the canEditAddress flags can be changed
    if (apiResponse.basket) {
      context.dispatch('updateBasket', apiResponse.basket);
    }

    validationResult.deliveryAddressValidationResult = apiResponse.delivery_address_validation_result;
    validationResult.invoiceAddressValidationResult = apiResponse.invoice_address_validation_result;

    return validationResult;
  },
  /**
   * Set the delivery/invoice address to a basket object. Will be first validated through the C*Vision Api.
   * @param {*} context
   * @param {*} data
   */
  async setAddressesToBasket(context, data) {
    const updateData = {
      delivery_address: { ...data.delivery_address },
      invoice_address: { ...data.invoice_address }
    };
    delete updateData.delivery_address.email_repeat;
    delete updateData.invoice_address.email_repeat;

    const result = {
      addressesUpdated: null,
      deliveryAddressErrors: null,
      invoiceAddressErrors: null,
      deliveryUpdateError: null,
      invoiceUpdateError: null,
      error: null
    };

    let apiResponse;
    try {
      apiResponse = await this.$api.setAddressToBasket(updateData);
    } catch (error) {
      apiResponse = error.response.data;
      result.error = error;
    }

    if (apiResponse.basket) {
      context.dispatch('updateBasket', apiResponse.basket);
      result.addressesUpdated = true;
    }

    result.deliveryAddressErrors = apiResponse.delivery_address_errors;
    result.invoiceAddressErrors = apiResponse.invoice_address_errors;
    result.deliveryUpdateError = apiResponse.delivery_update_error;
    result.invoiceUpdateError = apiResponse.invoice_update_error;
    result.addressesUpdated = false;

    return result;
  },
  /**
   * Returns the available payment options for the current basket.
   * @param {*} context
   * @throws ApiError
   * @returns {*} | null
   */
  async getAvailablePaymentOptions(context) {
    return await this.$api.retrieveAvailablePayments();
  },
  /**
   * Resets the basket object to the default state.
   * @param {*} context
   */
  resetBasket(context) {
    context.commit('RESET_BASKET');
  },
  /**
   * Sends a request to the backend, that the current user has checked the important hint checkbox for the given product
   * @param {*} context
   * @param {*} productNumber
   * @throws ApiError
   * @returns
   */
  setImportantHintCheckbox(context, productNumber) {
    return this.$api.setImportantHintCheckbox(productNumber).then((responseData) => {
      return responseData.result;
    });
  },

  async setTermsAndConditions(context, value) {
    return await this.$api.setTermsAndConditions(value);
  }
};

// =======================================================================
// Getters
// =======================================================================
const getters = {
  getLastProductInBasket: (state) => {
    return state.lastProduct;
  },
  getPositionsByDeliveryType: (state) => (deliveryType) => {
    return state.positions.filter((position) => position.delivery_type === deliveryType);
  },
  getPositionsWithDeliveryTypePost: (state) => {
    return state.positions.filter((pos) => [0].includes(pos.delivery_type));
  },
  getPositionsWithDeliveryTypeEmail: (state) => {
    return state.positions.filter((pos) => [1, 3, 4, 7, 8, 9].includes(pos.delivery_type));
  },
  getPositionsWithDeliveryTypeSMS: (state) => {
    return state.positions.filter((pos) => pos.delivery_type === 2);
  },
  positionsCounter: (state) => {
    if (!state.positions.length) return 0;
    return state.positions.reduce((ac, next) => ac + next.quantity, 0);
  },
  getUsedCreditAmount: (state) => {
    if (state.prepaidValue > state.grossPrice) {
      return state.grossPrice;
    } else {
      return state.prepaidValue;
    }
  },
  getRemainingPrepaidValue: (state, getters) => {
    return state.prepaidValue - getters.getUsedCreditAmount;
  },
  containsOfflineProducts: (state) => {
    return state.positions.some((position) => position.delivery_type === 0);
  },
  containsOnlineProducts: (state) => {
    return state.positions.some((position) => position.delivery_type === 1);
  },
  getPostPaymentAmount: (state) => {
    if (state.payments.find((payment) => payment.payment_type_id !== 2)) {
      return state.grossPrice - state.prepaidValue;
    }
    return 0;
  },
  isShortAddressSufficent: (state, getters, rootState, rootGetters) => {
    const webSiteSettings = rootState.app.websiteSettings;
    const isShortAddressAllowed = webSiteSettings.website_online_short_address_possible;
    return !getters.containsOfflineProducts && !state.completeInvoiceAddressNeeded && isShortAddressAllowed;
  },
  containsSufficentPostPayment: (state) => {
    return state.missingAmountOfMoney === 0 && state.payments.some((payment) => payment.payment_type_id !== 2);
  },
  getPostPaymentType: (state) => {
    return state.payments.find((payment) => payment.payment_type_id !== 2);
  },
  getPrepaidPayments: (state) => {
    const prepaidPaymentTypeIds = paymentTypes.filter((payment) => payment.prepaid).map((paymentType) => paymentType.id);
    return state.payments.filter((payment) => prepaidPaymentTypeIds.includes(payment.payment_type_id));
  },
  getVatTypesInBasket(state) {
    return state.positions
      .reduce((prev, curr) => {
        if (prev.includes(curr.vat)) return prev;
        prev = [...prev, curr.vat]
        return prev;
      }, [])
      .sort((a, b) => a.slice(0, -1) - b.slice(0, -1));
  },
  getNewsletterData(state) {
    return {
      firstName: state.deliveryAddress.first_name,
      lastName: state.deliveryAddress.last_name,
      email: state.deliveryAddress.email
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
