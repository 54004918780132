<template>
  <div class="product-filter">
    <div class="mb-3" data-test="filter-categories">
      <FilterCategories
        :categories="categories"
        :activeCategory="activeCategory"
        @update="updateCategoryFilter"
      />
    </div>
    <div class="mb-3" data-test="filter-product">
      <FilterBox
        :title="filterGroupsProductType.title"
        :options="filterGroupsProductType.options"
        :active-filter="productTypeFilter"
        @filterInput="updateProductTypeFilter"
      />
    </div>
    <div class="mb-3" data-test="filter-range">
      <FilterBoxRange
        group-id="price"
        :title="filterGroupsPrice.title"
        :min-range="priceRangeMin"
        :max-range="priceRangeMax"
        :price-min-filter="priceMinFilter"
        :price-max-filter="priceMaxFilter"
        :discount-filter="discountFilter"
        @filterRangeInput="updatePriceFilter"
        @discountClick="updatetDiscountFilter"
      />
    </div>
    <div class="mb-3" data-test="filter-shipping">
      <FilterBox
        :title="filterGroupsShippingMethod.title"
        :options="filterGroupsShippingMethod.options"
        :active-filter="shippingMethodFilter"
        @filterInput="updateShippingMethodFilter"
      />
    </div>
    <div class="mb-3" data-test="filter-redemption">
      <FilterBox
        :title="filterGroupsRedemptionType.title"
        :options="filterGroupsRedemptionType.options"
        :active-filter="redemptionTypeFilter"
        @filterInput="updateRedemptionTypeFilter"
      />
    </div>
    <!-- <div v-if="isBrandFilterVisible" class="mb-3" data-test="filter-brand">
      <FilterBrands :title="$t('filter.brands.headline')" :brands="brands" :active-brands="selectedBrands" @filterBrands="updateBrandFilter" />
    </div> -->
  </div>
</template>

<script>
  import FilterCategories from './FilterCategories.vue';
  import FilterBox from './FilterBox.vue';
  import FilterBoxRange from './FilterBoxRange.vue';
  import FilterBrands from './FilterBrands.vue';
  import { mapGetters, mapState } from 'vuex';

  export default {
    components: {
      FilterCategories,
      FilterBox,
      FilterBoxRange,
      FilterBrands
    },
    computed: {
      ...mapGetters({
        priceRangeMin: 'filter/getMinPriceOfAllProducts',
        priceRangeMax: 'filter/getMaxPriceOfAllProducts',
        isSearchActive: 'filter/isSearchActive',
        brands: 'filter/getBrands',
        categories: 'filter/getCategories',
        composedQuery: 'filter/composedQuery',
        isRedemptionShop: 'app/isRedemptionShop'
      }),
      ...mapState({
        productTypeFilter: (state) => state.filter.productType,
        redemptionTypeFilter: (state) => state.filter.redemptionType,
        shippingMethodFilter: (state) => state.filter.shippingMethod,
        discountFilter: (state) => state.filter.discount,
        showOnlyRecommendedProducts: (state) => state.filter.onlyRecommendations,
        searchInput: (state) => state.filter.searchInput,
        selectedBrands: (state) => state.filter.selectedBrands,
        category: (state) => state.filter.category,
        activeCategory: (state) => state.filter.activeCategory,
        priceMinFilter: (state) => state.filter.priceMin,
        priceMaxFilter: (state) => state.filter.priceMax,
        allProducts: (state) => state.product.catalogProducts
      }),
      // isBrandFilterVisible() {
      //   return this.productTypeFilter.some(el => {
      //     return el.value === 'PHY'
      //   });
      // },
      filterGroupsProductType() {
        if (this.isRedemptionShop) {
          const productType = [
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_vouchers'),
              value: 'TVO'
            },
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_brand_products'),
              value: 'PHY'
            }
          ];
          const filteredOptions = this.filterHandler(productType, this.allProducts, {redemption: false});
          return {
            title: this.$t('filter.group.type.title'),
            options: filteredOptions
          };
        } else {
          const groupTypes = [
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_vouchers'),
              value: 'TVO' || 'UVO'
            },
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_brand_products'),
              value: 'PHY'
            },
            {
              groupId: 'productType',
              text: this.$t('filter.group.type.option_theme_vouchers'),
              value: 'UVO'
            },
          ]
          const filteredOptions = this.filterHandler(groupTypes, this.allProducts, {redemption: false});
          return {
            title: this.$t('filter.group.type.title'),
            options: filteredOptions
          };
        }
        
      },
      filterGroupsPrice() {
        return {
          title: this.$t('filter.group.price.title', { currency: this.$store.state.app.currency.code })
        };
      },
      filterGroupsShippingMethod() {
        // For the shipping method option we need to add the correct text for the filter options
        // As well as are we going to group some of them because they share the same name
        const shippingOptions = [
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_post'),
            value: 0
          },
          {
            groupId: 'shippingMethod',
            text: this.$t('filter.group.shipping.option_mail'),
            value: 1
          }
        ];
        return {
          title: this.$t('filter.group.shipping.title'),
          options: shippingOptions
        };
      },
      filterGroupsRedemptionType() {
        const redemptionType = [
          {
            groupId: 'redemptionType',
            text: this.$t('filter.group.property.option_online'),
            value: 'online_redeemable'
          },
          {
            groupId: 'redemptionType',
            text: this.$t('filter.group.property.option_store'),
            value: 'offline_redeemable'
          },
          {
            groupId: 'redemptionType',
            text: this.$t('filter.group.property.option_print'),
            value: 'printable_as_barcode'
          },
          {
            groupId: 'redemptionType',
            text: this.$t('filter.group.property.option_mobile'),
            value: 'usable_on_smartphone'
          },
        ]
        const filteredOptions = this.filterHandler(redemptionType, this.allProducts, {redemption: true});
        return {
          title: this.$t('filter.group.property.title'),
          options: filteredOptions
        };
      }
    },

    methods: {
      updateProductTypeFilter(filterArray) {
        this.$store.commit('filter/SET_PRODUCTTYPE', filterArray);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateShippingMethodFilter(filterArray) {
        this.$store.commit('filter/SET_SHIPPINGMETHOD', filterArray);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateRedemptionTypeFilter(filterArray) {
        this.$store.commit('filter/SET_REDEMPTIONTYPE', filterArray);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updatetDiscountFilter(discountValue) {
        this.$store.commit('filter/SET_DISCOUNT', discountValue);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updatePriceFilter(priceObject) {
        this.$store.dispatch('filter/updatePriceFilter', priceObject);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateBrandFilter(selectedBrands) {
        this.$store.commit('filter/SET_SELECTED_BRANDS', selectedBrands);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },
      updateCategoryFilter(category) {
        this.$store.commit('filter/SET_CATEGORY', category);
        this.$store.commit('filter/SET_PAGE', 1);
        this.$router.push({ path: this.$routeHandler('/product-overview'), query: this.composedQuery });
      },

      filterHandler(group, products, {redemption}) {
        const result = group.reduce((item, list) => {
        let x = [];
          if (redemption) {
            x = products.find(product => {
              return product.hasOwnProperty(list.value) && product[list.value];
            })
          } else {
            x = products.find(product => product.type_group === list.value);
          }
          if(x) {
            return [...item, list];
          } else {
            return item.map(i => i.type_group === list.value ? ({...i, text: i.text + list.text }) : i)
          }
        }, []);
        return result;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .product-filter {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
</style>
