<template>
  <div class="row card-body">
    <div class="col">
      <form @submit.prevent="createNewPassword">
        <div role="group" class="form-group mb-0">
          <label label="newPassword" />
          <div class="position-relative">
            <input
              id="newPassword"
              type="password"
              ref="newPassword"
              :placeholder="$t('global.new_password_form.placeholder')"
              :class="{ 'input-field': newPassword, 'is-invalid': invalidState === false }"
              class="form-control"
              :aria-invalid="!invalidState"
              v-model="newPassword"
            />
            <b-icon class="password_icon" @click="showPassword(1)" :icon="isClicked !== true ? 'hide-password-icon' : 'show-password-icon'" />
          </div>
        </div>

        <div role="group" class="form-group" :class="[notSamePasswordError ? 'mb-5' : 'mb-4']">
          <label label="confirmPassword" />
          <div class="position-relative">
            <input
              id="confirmPassword"
              ref="confirmPassword"
              type="password"
              :placeholder="$t('global.new_password_form.repeat_placeholder')"
              :class="{ 'input-field': confirmNewPassword, 'is-invalid': invalidState === false }"
              class="form-control"
              :aria-invalid="!invalidState"
              v-model="confirmNewPassword"
            />
            <b-icon class="password_icon" @click="showPassword(2)" :icon="confirmClick !== true ? 'hide-password-icon' : 'show-password-icon'" />
            <div class="invalid-feedback position-absolute">{{ notSamePasswordError }}</div>
          </div>
        </div>

        <div v-if="!websiteSettings.deny_captcha" class="hcaptcha-container">
          <VueHcaptcha ref="hcaptcha" :sitekey="hcaptchaKey" :language="$store.state.translation.locale" @error="onError" @verify="onSuccess" @expired="onExpired" />
        </div>

        <DefaultButton
          type="submit"
          block
          variant="primary"
          :disabled="disabled"
          :button-text="$t('global.new_password_form.button')"
          :loading="loading"
          data-test="new-password-input-button"
          />

        <div class="mt-4">
          <p class="mb-1 " :class="[ hasCharacters ? 'text-danger' :  'success']">
            <b-icon v-if="!hasCharacters" icon="password-check" />
            {{ $t('global.new_password_form.max_characters') }}
          </p>
          <p class="mb-1" :class="[ hasUperCase ? 'text-danger' :  'success']">
            <b-icon v-if="!hasUperCase" icon="password-check" />
            {{ $t('global.new_password_form.one_capital_letter') }}
          </p>
          <p class="mb-1" :class="[ hasNumber ? 'text-danger' :  'success']">
            <b-icon v-if="!hasNumber" icon="password-check" />
            {{ $t('global.new_password_form.one_number') }}
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import BIcon from 'building-blocks/components/BIcon.vue';
  import { mapState } from 'vuex';
  import { useRoute } from 'vue-router'

  export default {
    data() {
      return {
        invalidState: null,
        confirmNewPassword: '',
        newPassword: '',
        errorMessage: '',
        isClicked: false,
        confirmClick: false,
        captchaToken: '',
        passwordToken: '',
      }
    },
    components: {
      DefaultButton,
      BIcon,
      VueHcaptcha
    },
    computed: {
      ...mapState({
        loading: (state) => state.app.isLoading,
        websiteSettings: (state) => state.app.websiteSettings
      }),
      hcaptchaKey() {
        return process.env.VUE_APP_HCAPTCHA_SITE_KEY;
      },

      samePassword() {
        return this.newPassword !== this.confirmNewPassword;
      },
      disabled() {
        return this.newPassword === '' || this.confirmNewPassword === '' || this.hasCharacters || this.hasNumber || this.hasUperCase || (!this.websiteSettings.deny_captcha && !this.captchaToken);
      },

      hasCharacters() {
        return this.newPassword.length <= 9;
      },
      hasNumber() {
        return !/\d/.test(this.newPassword);
      },
      hasUperCase() {
        return !/\p{Lu}/u.test(this.newPassword);
      },

      notSamePasswordError() {
        return this.errorMessage;
      }
    },
    methods: {
      toast(message, error) {
        const toastData = {
          message,
          duration: 7
        };
        this.$store.commit('app/SET_TOAST', toastData);
      },
      onError(error) {
        if (!error.handled) {
          this.toast(this.$t('form.error.hcaptcha', { error }));
        }
      },
      onSuccess(captchaToken) {
        this.captchaToken = captchaToken;
      },
      async onExpired() {
        await this.$refs.hcaptcha.reset();
      },
      setLoading(state) {
        this.$store.commit('app/SET_LOADINGSTATE', state);
      },

      createNewPassword() {
        this.setLoading(true);
        if(this.samePassword) {
          this.invalidState = false;
          this.errorMessage = this.$t('api-errors.new_password.dont_match');
          this.setLoading(false);
        } else {
          this.submit();
        }
      },

      async submit() {
        this.errorMessage = null;
        this.invalidState = true;
        const newData = {
          new_password: this.newPassword,
          new_password_retype: this.confirmNewPassword,
          captcha: this.websiteSettings.deny_captcha ? '' : this.captchaToken,
          password_token: this.passwordToken,
          external_identifier: this.websiteSettings.external_identifier
        }
        try {
          const response = await this.$api.handleNewPassword(newData);
          if(response.status === 204) {
            this.$emit('formSubmit');
          }
          this.setLoading(false);
        } catch (error) {
          this.setLoading(false);
          if(!this.websiteSettings.deny_captcha) await this.$refs.hcaptcha.reset();
          throw this.toast(this.$t(error.message));
        }
      },

      showPassword(value) {
        if(value === 1) {
          let newPass = this.$refs.newPassword;
          this.isClicked = !this.isClicked;
          newPass.type = newPass.type === 'password' ? 'text' : 'password';
        } else {
          let confirmNewPass = this.$refs.confirmPassword
          this.confirmClick = !this.confirmClick;
          confirmNewPass.type = confirmNewPass.type === 'password' ? 'text' : 'password';
        }
      },

      async validateToken(token, website_uuid) {
        let response;
        const errorMessage = this.$t('api-errors.general.INTERNAL_SERVER_ERROR');
        const data = {
          token: encodeURI(token),
          external_identifier: website_uuid
        }
        try {
          response = await this.$api.handleTokenValidation(data);
        } catch (error) {
          this.setLoading(false);
          this.$router.push(this.$routeHandler('/'));
          throw this.toast(errorMessage);
        }

        return response?.reset_password_token
      }
    },

    async mounted() {
      const route = useRoute();
      const token = route.query.token;
      const website_uuid = route.query.website_uuid;
      if(!token || !website_uuid) {
        this.setLoading(false);
        this.$router.push(this.$routeHandler('/'));
        const errorMessage = this.$t((!token && 'api-errors.new_password.missing_token') || (!website_uuid && 'api-errors.new_password.missing_uuid'));
        throw this.toast(errorMessage);
      }
      this.passwordToken = await this.validateToken(token, website_uuid);
    }
  }
</script>

<style lang="scss" scoped>
  .invalid-feedback {
    text-align: left;
    font-size: $font-size-md;
    font-weight: bold;
  }
  .form-control.is-invalid {
    background-image: none;
  }
  .success {
    color: #1AA349;
  }
  .password_icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
</style>