<template>
  <header ref="header" id="header" class="header bg-white sticky-header" :class="{ 'logged-out': !headerLoggedIn }">
    <HeaderCustomerInfo :logo="logo" :loggedIn="headerLoggedIn" />
    <HeaderNavigation v-if="showNavigation" :loggedIn="headerLoggedIn" :logo="logo" :links="links" />
  </header>
</template>

<script>
  import HeaderCustomerInfo from './HeaderCustomerInfo.vue';
  import HeaderNavigation from './HeaderNavigation.vue';

  export default {
    components: {
      HeaderCustomerInfo,
      HeaderNavigation
    },
    props: {
      logo: {
        type: String,
        default: require('@/assets/images/BestChoice_Logo_4c_pos_grau.svg').default
      },
      loggedInLinks: {
        type: Array,
        default: undefined
      },
      loggedOutLinks: {
        type: Array,
        default: undefined
      }
    },
    computed: {
      headerLoggedIn() {
        return this.$store.state.app.headerLoggedIn;
      },
      showNavigation() {
        return this.$route.meta.showNavigation == false ? false : true;
      },
      links() {
        return this.headerLoggedIn ? this.loggedInLinks : this.loggedOutLinks;
      },
      activeGiftomatic() {
        return this.$store.getters['pages/activeGiftomatic'];
      }
    },
    
    beforeMount() {
      if (this.activeGiftomatic) {
        this.loggedInLinks.push({name: this.$t('global.navigation_items.giftomatic'), path: '/product-finder'});
      }
    },

    mounted() {
      if (this.$store.state.app.mgnlMode) return;

      let lastPos = 0;
      const header = this.$refs.header;

      setTimeout(() => {
        const links = header.querySelectorAll('nav a');
        const anchors = [...links]
          .filter((link) => link.hash && link.hash !== '#app' && document.querySelector(link.hash))
          .map((link) => {
            const section = document.querySelector(link.hash);
            return { el: link, yMin: section.offsetTop - 30, yMax: section.offsetTop - 30 + section.offsetHeight };
          });

        window.addEventListener('scroll', () => {
          const scrollPos = window.scrollY || document.documentElement.scrollTop;

          // detects new state and compares it with the new one
          if (scrollPos < lastPos) {
            document.getElementById('header')?.setAttribute('data-scroll-direction', 'up');
          } else {
            document.getElementById('header')?.setAttribute('data-scroll-direction', 'down');
          }
          lastPos = scrollPos <= 0 ? 0 : scrollPos;

          anchors.forEach((anchor) => {
            anchor.el.classList.remove('active');
            if (scrollPos >= anchor.yMin && scrollPos < anchor.yMax) anchor.el.classList.add('active');
          });
        });
      }, 500);
    }
  };
</script>

<style lang="scss">
  .header {
    box-shadow: $box-shadow-sm;
  }
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: $z-index-high;
    transition: top 0.2s;
  }
  .sticky-header[data-scroll-direction='up'] {
    top: 0;
  }
  .sticky-header[data-scroll-direction='down'] {
    top: -3rem;

    &.logged-out {
      top: -3.5rem;
    }

    @include media-breakpoint-up(md) {
      &.logged-out {
        top: -5rem;
      }
    }

    @include media-breakpoint-up(lg) {
      &,
      &.logged-out {
        top: -5rem;
      }
    }
  }
</style>
