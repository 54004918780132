<template>
  <Loader/>
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import Loader from 'building-blocks/components/Loader.vue';
  export default {
    components: { Loader },
    computed: {
      ...mapState({
        basketNumber: (state) => state.basket.basketNumber
      }),
      ...mapGetters({
        postPaymentType: 'basket/getPostPaymentType'
      }),
    },
    async created() {
      const params = this.$route.query;
      const verify = await this.$store.dispatch('basket/verifySaferpay', params);
      await this.$store.dispatch('basket/paymentInProcess', 'false');
      if (verify) {
        await this.$store.dispatch('order/createOrder', this.basketNumber);
        await this.$store.dispatch('user/logOut');
        await this.$store.dispatch('basket/resetBasket');
        this.$store.commit('app/SET_HEADER_LOGGEDIN', false);
        this.$router.push({ name: 'checkout-confirmation' });
      } else {
        await this.$store.dispatch('basket/resetSerialized');
        await this.$store.dispatch('basket/removePaymentType', this.postPaymentType);
        const err = { err: true };
        this.$router.push({ name: 'checkout-payment', params: { err } });
      }
    }
  };
</script>
